/* eslint-disable react/no-danger */
import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// import "@stylesPages/About.scss";

// import { ContactForm } from "@containers";
// import { Footer, Header, SEO } from "@components";

import "../styles/pages/About.scss";

import { ContactForm } from "../containers";
import { Footer, Header, SEO } from "../components";

const Contact = () => {
  const { t } = useTranslation("about");

  return (
    <div className="about">
      <Header />
     
      <section className="about__section about__section--column about__section--contact">
        <h3 className="about__title about__title--contact-sub">{t("contact-subtitle")}</h3>
        <h2 className="about__title about__title--contact">{t("contact-title")}</h2>
        <div className="about__contact-content">
          <address className="about__contact-box-text">
            <p className="about__text about__text--contact-info">{t("contact-text-info-2")}</p>
            <p className="about__text about__text--contact-info">{t("contact-text-info-3")}</p>
          </address>
          <div className="about__contact-box-form">
            <ContactForm />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Contact;

export const Head = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "about"));

  const { "about-title": titleSeo } = about ? JSON.parse(about.node.data) : {
    "about-title": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;